import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function FaucetRepair() {
  const data = useStaticQuery(graphql`
    query kleinImages {
      kleinPlumber: file(relativePath: { eq: "klein-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Klein Plumbing Company"
        description={`Our company has been providing plumbing services for the Klein community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Klein Plumbing Company`}
        h2={`Your Local Master Plumber`}
        h3={`Our plumbing company is built and based in Klein, Texas. We're proud to serve the Klein community and surrounding area.`}
        p1={`With over 30 years of experience serving Klein residents, we're confident that we can handle any plumbing repair. Our service is thorough, fast, and affordable so you can feel secure in knowing that your home is in top shape.`}
        heroImg={data.kleinPlumber.childImageSharp.fluid}
        heroImgAltTag={`Klein Plumbing Company`}
      />
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default FaucetRepair
